import classNames from 'classnames'
import React from 'react'
import TabOptions from '../types/enums/TabOptions'
import { useSearch } from '../state/useSearch'
import * as UI from '@brewlogix/blx-ui'

const SearchTabs: React.FC = () => {
    const { activeTab, setActiveTab } = useSearch()
    return (
        <div className='relative flex flex-row items-center justify-start overflow-hidden after:absolute after:bottom-0 after:z-0 after:h-px after:w-full after:bg-gray-100 after:content-[""] md:pt-2'>
            {Object.entries(TabOptions).map((item) => (
                <UI.Button
                    key={item[0]}
                    label={item[1]}
                    theme='link'
                    size='md'
                    customClasses={classNames(
                        'relative z-10 border-0 px-5  md:px-12 hover:text-success',
                        activeTab === TabOptions[item[0]]
                            ? 'after:bg-success text-primary after:opacity-100'
                            : 'text-medium'
                    )}
                    onClick={() => setActiveTab(TabOptions[item[0]])}
                />
            ))}
        </div>
    )
}

export default SearchTabs
