import { regions } from 'src/util/constants'
import getLocationPlaces from './getLocationPlaces'
import { getCapitalCity } from './statesWithCities'

interface IUserLocation {
    coords?: [number, number]
    city: string
    state: string
    address?: string
}

const parseUserLocationHeaders = async (locationData: IUserLocation): Promise<IUserLocation> => {
    let address: string
    let city: string
    let state: string
    let coords: IUserLocation['coords'] = locationData?.coords
    if (locationData?.state) {
        state = regions[decodeURI(locationData.state)] || decodeURI(locationData.state)
        city = locationData?.city || getCapitalCity(locationData.state)
        address = `${decodeURI(city)}, ${state}`
    } else {
        state = regions.CA
        city = 'San Diego'
        address = `${city}, ${state}`
    }

    if (!coords) coords = await getLocationPlaces(address)

    return {
        coords,
        city,
        state,
        address,
    }
}

export default parseUserLocationHeaders
