import React, { FormEvent } from 'react'
import { useSearch } from '../state/useSearch'
import * as UI from '@brewlogix/blx-ui'
import { ISearchConfig } from '../types'
import classNames from 'classnames'
import * as CC from '@chakra-ui/react'

interface SearchFormProps {
    config: ISearchConfig
    onSubmit?: (e: FormEvent) => void
    customClasses?: string
}

const SearchForm: React.FC<SearchFormProps> = ({ config, onSubmit, customClasses }) => {
    const { searchTerm, setSearchTerm, setShowResults } = useSearch()

    return (
        <form
            onSubmit={(e) => onSubmit(e)}
            id='search-form'
            name='search-form'
            action='/search'
            method='GET'
            className={classNames(
                'flex w-full flex-row items-center justify-between',
                customClasses
            )}
        >
            <UI.Icon
                slug='HeroIcons.Search'
                size='lg'
                customClasses='absolute left-3 z-30 mx-2 my-1 h-6 w-6 md:left-4 md:mx-6 md:my-2 md:h-7 md:w-7'
            />
            <CC.Input
                id='searchTerm'
                type='search'
                placeholder={config.label}
                className='live-search__input relative z-20 w-full border-transparent py-6 pl-12 text-lg md:py-8 md:pl-24 md:text-xl'
                name='searchTerm'
                size='lg'
                onChange={({ target }) => setSearchTerm(target?.value || '')}
                onFocus={() => setShowResults(true)}
                value={searchTerm || ''}
                autoComplete='off'
            />
            <UI.Button
                type='submit'
                label={config.buttonLabel}
                aria-label='Search'
                theme='primary'
                size='lg'
                customClasses='absolute right-2 z-20 px-4 md:right-4 md:px-8'
            />
        </form>
    )
}

export default SearchForm
