const accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

const getLocationPlaces = async (query: string) => {
    let coords: [number, number]
    if (query !== null) {
        await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                query
            )}.json?access_token=${accessToken}&limit=10&autocomplete=true&country=US&types=place`
        )
            .then((response) => response.text())
            .then((res) => JSON.parse(res))
            .then((json) => {
                const places = json.features
                if (places?.length > 0) {
                    coords = [places[0].center[1], places[0].center[0]]
                } else {
                    coords = [32.71742, -117.162773] // San Diego, CA as a final fallback
                }
            })
            .catch((err) => console.log('getLocationPlaces fetch(mapboxPlacesApi) Error', err))

        return coords
    }
}

export default getLocationPlaces
