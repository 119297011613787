const staticSearchConfig = {
    limit: 10,
    label: 'Search',
    buttonLabel: 'Search',
    moreResults: 'search',
    moreResultsLabel: 'Next Page',
    noResultsLabel: 'No Results',
    tabs: true,
    iconMap: {
        Brand: 'Tank',
        Beer: 'Glass',
        Cider: 'Cider',
        Coffee: 'Coffee',
        Kombucha: 'Kombucha',
        Location: 'HeroIcons.LocationMarker',
    },
}

export default staticSearchConfig
