import React from 'react'
import * as UI from '@brewlogix/blx-ui'
import liveSearchConfig from '../../config/liveSearchConfig'

const LiveSearchNoResults: React.FC<{ message?: string }> = ({ message }) => {
    return (
        <UI.Card
            format='inline'
            customClasses='w-full text-medium'
            item={{
                title: liveSearchConfig.noResultsLabel || 'No Results',
                subtitle: message || 'Try a different keyword.',
            }}
            image={<UI.Icon size='lg' slug='HeroIcons.EmojiSad' theme='medium' />}
        />
    )
}

export default LiveSearchNoResults
