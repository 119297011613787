const searchBPLLocations = /* GraphQL */ `
query SearchBPL($input: inputSearchBPL!) {
    searchBPL(input: $input) {  
        nextToken
        returnedCount
        totalResults
        items {
            __typename
            id
            ...on Location {
                assets {
                    __typename
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                        path
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
                title
                slug
                hours {
                    day
                    isOpen
                    hours
                }
                description
                address {
                    city
                    country
                    geo {
                        lat
                        lon
                    }
                    postalCode
                    state
                    street
                    street2
                }
                locationHighlights {
                    description
                }
                traits {
                    brewOptions
                    foodOptions
                    patronOptions
                    seatingOptions
                    isOpenToPublic
                    tourInfo {
                        toursAvailable
                        mustBeLegalDrinkingAge
                        description
                        cost
                        hours {
                            day
                            isOpen
                            hours
                        }
                    }
                    isPrimary
                    type
                    status
                }
                brand {
                    __typename
                    id
                    title
                    displayTitle
                    description
                    slug
                    website
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                    products {
                        __typename
                        id
                        slug
                        title
                        abv
                        brand {
                        slug
                        title
                        displayTitle
                        address {
                            city
                            state
                        }
                        }
                        ... on Beer {
                            style {
                                title
                            }
                        }
                    }
                    address {
                        city
                        country
                        postalCode
                        state
                        street
                        street2
                    }
                    traits {
                        isIndependent
                    }
                }
            }
        }            
    }
}
`

export default searchBPLLocations
