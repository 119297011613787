import { parseStateLabel } from 'src/features/BrewDetail/util/helpers'
import { CompositeSearchResult } from '../../types'
import { Brand, CompositeProduct, Location } from 'src/types/schema/graphql'
import { parseEnumLabel } from 'src/util/helpers'

const determineSearchDisplayText = (item: CompositeSearchResult) => {
    if (!item) return ''
    switch (item.__typename) {
        case 'Brand':
            return `${(item as Brand)?.address?.city}, ${parseStateLabel(
                (item as Brand)?.address?.state
            )}`
        case 'Beer':
            item = item as CompositeProduct
            const beerStyle = item.styleAlias ? item.styleAlias : item.style?.title
            const title = item.brand?.title || item.brand?.displayTitle
            return `${title} - ${beerStyle}, ${item.abv?.toFixed(1)}%`
        case 'Cider':
            item = item as CompositeProduct
            const ciderStyle = item.styleAlias || parseEnumLabel(item.ciderFamily) || 'Cider'
            return `${item.brand?.title} - ${ciderStyle}, ${item.abv?.toFixed(1)}%`
        case 'Coffee':
            return item.title
        case 'Kombucha':
            item = item as CompositeProduct
            const kombuchaStyle =
                item.styleAlias || parseEnumLabel(item.kombuchaFamily) || 'Kombucha'
            return `${item.brand?.title} - ${kombuchaStyle}, ${item.abv?.toFixed(1)}%`
        case 'Location':
            return `${item.brand?.title} - ${(item as Location)?.address?.street}, ${
                (item as Location)?.address?.city
            }, ${parseStateLabel((item as Location)?.address?.state)}`
        default:
            return item.title
    }
}

export default determineSearchDisplayText
