import React from 'react'
import * as UI from '@brewlogix/blx-ui'

const LiveSearchError: React.FC = () => {
    return (
        <div className='search-results__item--error pointer-events-none w-full transition-all duration-200 ease-in'>
            <UI.Card
                format='inline'
                item={{
                    title: 'Connection Error',
                    subtitle: 'I must have mixed up my IPAs and APIs again.',
                }}
                image={<UI.Icon size='lg' slug='HeroIcons.Exclamation' theme='error' />}
                customClasses='w-full text-error'
            />
        </div>
    )
}

export default LiveSearchError
