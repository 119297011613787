import { CompositeSearchResult } from '../../types'

const parseItemPath = (item: CompositeSearchResult): string => {
    let itemPath = ''
    switch (item?.__typename) {
        case 'Brand':
            itemPath = `brewer/${item?.slug}`
            break
        case 'Beer':
        case 'Cider':
        case 'Coffee':
        case 'Kombucha':
        case 'Mead':
        case 'Non__Alcoholic':
        case 'Seltzer':
            itemPath = `brewer/${item?.brand.slug}/products/${item?.slug}`
            break

        case 'Location':
            itemPath = item?.brand ? `brewer/${item.brand.slug}/locations/${item?.slug}` : null
            break

        default:
            itemPath = item?.slug || ''
    }
    return itemPath
}

export default parseItemPath
