import { CompositeSearchResult } from '../../types'

const productTypes = ['Beer', 'Cider', 'Kombucha', 'Mead']

const getItemsByType = (
    items: CompositeSearchResult[]
): {
    brands: CompositeSearchResult[]
    products: CompositeSearchResult[]
    locations: CompositeSearchResult[]
} => {
    const brands = items?.filter((item) => item?.__typename === 'Brand')
    const products = items?.filter((item) => productTypes.includes(item?.__typename))
    const locations = items?.filter((item) => item?.__typename === 'Location')

    return { brands, products, locations }
}

export default getItemsByType
