import { InputSearchBrandsLocationsProducts } from 'src/types/schema/graphql'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { CompositeSearchResult } from '../types'
import TabOptions from '../types/enums/TabOptions'
import { getSearchResults, getItemsByType } from '../utils/helpers'
import staticSearchConfig from '../config/staticSearchConfig'
import determineTypeFilters from '../utils/helpers/determineTypeFilters'
import { gtmEvent } from 'src/features/tracking/utils/gtm'
import Router from 'next/router'

interface IDefaultData {
    activeTab: TabOptions
    apiData: CompositeSearchResult[]
    brands: CompositeSearchResult[]
    currentPage: number
    error: Error
    isSearching: boolean
    locations: CompositeSearchResult[]
    nextToken?: string
    products: CompositeSearchResult[]
    searchIsReady: boolean
    results: CompositeSearchResult[]
    searchTerm: string
    showResults: boolean
    totalResults: number
}

interface IUseSearch extends IDefaultData {
    getResults: (options?: InputSearchBrandsLocationsProducts) => Promise<void>
    nextPage: () => void
    prevPage: () => void
    resetSearchStore: () => void
    setActiveTab: (tab: TabOptions) => void
    setCurrentPage: (page: number) => void
    setIsSearching: (searching: boolean) => void
    setNextToken: (offset: string) => void
    setSearchTerm: (term: string) => void
    setShowResults: (show: boolean) => void
}

const defaultData: IDefaultData = {
    activeTab: TabOptions.All,
    apiData: [],
    brands: [],
    currentPage: 1,
    error: null,
    isSearching: true,
    locations: [],
    nextToken: null,
    products: [],
    searchIsReady: false,
    results: [],
    searchTerm: '',
    showResults: false,
    totalResults: 0,
}

export const useSearch = create<IUseSearch>()(
    devtools((set, get) => ({
        ...defaultData,
        setActiveTab: (tab) => set({ activeTab: tab }),
        setIsSearching: (searching) => set({ isSearching: searching }),
        setShowResults: (show) => set({ showResults: show }),
        setSearchTerm: (term) => set({ searchTerm: term }),
        setCurrentPage: (page) => set({ currentPage: page }),
        setNextToken: (offset) => set({ nextToken: offset }),
        nextPage: () => {
            set({ currentPage: get().currentPage + 1 })
            get().getResults({
                limit: staticSearchConfig.limit,
                nextToken: get().nextToken,
                search: {
                    typeFilters: determineTypeFilters(get().activeTab),
                },
            })
        },
        prevPage: () => {
            set({ currentPage: get().currentPage - 1 })
            const offset = Number(get().nextToken) - staticSearchConfig.limit * 2
            get().getResults({
                limit: staticSearchConfig.limit,
                nextToken: String(offset < 0 ? 0 : offset),
                search: {
                    typeFilters: determineTypeFilters(get().activeTab),
                },
            })
        },
        getResults: async (options) => {
            get().setIsSearching(true)
            const apiData = await getSearchResults(get().searchTerm, options)
            gtmEvent({
                event: 'search',
                search_term: get().searchTerm,
                search_filter: get().activeTab,
                search_source: Router.asPath?.includes('/search') ? 'static_search' : 'live_search',
            })

            let results: CompositeSearchResult[]
            const { brands, products, locations } = getItemsByType(apiData)

            if (get().activeTab === TabOptions.All) results = apiData
            if (get().activeTab === TabOptions.Brand) results = brands
            if (get().activeTab === TabOptions.Location) results = locations
            if (get().activeTab === TabOptions.Product) results = products
            set({
                apiData,
                results: results?.slice(0, options?.limit || 5),
                isSearching: false,
                searchIsReady: true,
            })
        },
        resetSearchStore: () => {
            set(defaultData)
        },
    }))
)
