import { RequestInput, apiRequest } from 'src/api/apiRequest'
import {
    InputSearchBpl,
    Location,
    LocationStatus,
    OpenSearchType,
    WildCardTitleSearchConnection,
} from 'src/types/schema/graphql'
import { CompositeSearchResult } from '../../types'
import searchBPLLocations from '../graph/searchBPLLocations'

const parseLocationResults = (locations: Location[]): CompositeSearchResult[] => {
    const brands = [
        locations[Math.floor(Math.random() * locations.length)].brand,
        locations[Math.floor(Math.random() * locations.length)].brand,
        locations[Math.floor(Math.random() * locations.length)].brand,
        locations[Math.floor(Math.random() * locations.length)].brand,
        locations[Math.floor(Math.random() * locations.length)].brand,
        locations[Math.floor(Math.random() * locations.length)].brand,
    ]
    const products = []
    brands.map((brand) => {
        products.push(...brand?.products.filter((item, index) => index < 3))
        return null
    })

    const mixedresults = [...brands, ...locations, ...products]
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    return mixedresults
}

const getNearbyLocations = async (lat: number, lon: number, radius: number) => {
    let mixedResults: CompositeSearchResult[]
    const input: RequestInput<InputSearchBpl> = {
        query: searchBPLLocations,
        args: {
            input: {
                limit: 5,
                typeFilters: [OpenSearchType.Location],
                search: {
                    wildcardText: '',
                    searchFieldsLocations: {
                        geoRadiusSearch: {
                            geoRadius: radius || 20,
                            geoPoint: {
                                lat,
                                lon,
                            },
                        },
                        status: LocationStatus.OpenForBusiness,
                    },
                },
                nextToken: null,
            },
        },
        file: 'getNearbyLocations.ts',
        endpoint: 'searchBPL',
        publicQuery: true,
    }
    const { data } = await apiRequest<WildCardTitleSearchConnection, InputSearchBpl>(input)

    if (data) {
        const mixedResults = parseLocationResults(data?.items)
        return mixedResults
    } else return []
}

export default getNearbyLocations
