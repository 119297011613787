import { OpenSearchType } from 'src/types/schema/graphql'
import TabOptions from '../../types/enums/TabOptions'

const determineTypeFilters = (activeTab: TabOptions): OpenSearchType[] => {
    if (activeTab === TabOptions.All) return null

    if (activeTab === TabOptions.Brand) return [OpenSearchType.Brand]
    if (activeTab === TabOptions.Product) return [OpenSearchType.Product]
    if (activeTab === TabOptions.Location) return [OpenSearchType.Location]
}

export default determineTypeFilters
