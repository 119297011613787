import { statesUS } from 'src/util/constants'

export const parseStateLabel = (name = null) => {
    if (!name) return ''
    if (name && name.length === 2) {
        return name
    } else {
        const stateLabel = Object.values(statesUS)
            .filter((item) => {
                return item.label === name
            })
            .map((item) => {
                return item.code
            })
        return stateLabel && stateLabel.length ? stateLabel : name
    }
}
