const liveSearchConfig = {
    limit: 5,
    label: 'Start Exploring',
    buttonLabel: 'Search',
    moreResults: 'search',
    moreResultsLabel: 'More Results',
    noResultsLabel: 'No Results',
    tabs: true,
    iconMap: {
        Brand: 'Tank',
        Beer: 'Glass',
        Cider: 'Cider',
        Coffee: 'Coffee',
        Kombucha: 'Kombucha',
        Location: 'HeroIcons.LocationMarker',
    },
}

export default liveSearchConfig
