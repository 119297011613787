export const statesWithCities = {
    AL: {
        label: 'Alabama',
        capital: 'Alabama',
    },
    AK: {
        label: 'Alaska',
        capital: 'Alaska',
    },
    AZ: {
        label: 'Arizona',
        capital: 'Arizona',
    },
    AR: {
        label: 'Arkansas',
        capital: 'Arkansas',
    },
    CA: {
        label: 'California',
        capital: 'California',
    },
    CO: {
        label: 'Colorado',
        capital: 'Colorado',
    },
    CT: {
        label: 'Connecticut',
        capital: 'Connecticut',
    },
    DE: {
        label: 'Delaware',
        capital: 'Delaware',
    },
    FL: {
        label: 'Florida',
        capital: 'Florida',
    },
    GA: {
        label: 'Georgia',
        capital: 'Georgia',
    },
    HI: {
        label: 'Hawaii',
        capital: 'Hawaii',
    },
    ID: {
        label: 'Idaho',
        capital: 'Idaho',
    },
    IL: {
        label: 'Illinois',
        capital: 'Illinois',
    },
    IN: {
        label: 'Indiana',
        capital: 'Indiana',
    },
    IA: {
        label: 'Iowa',
        capital: 'Iowa',
    },
    KS: {
        label: 'Kansas',
        capital: 'Kansas',
    },
    KY: {
        label: 'Kentucky',
        capital: 'Kentucky',
    },
    LA: {
        label: 'Louisiana',
        capital: 'Louisiana',
    },
    ME: {
        label: 'Maine',
        capital: 'Maine',
    },
    MD: {
        label: 'Maryland',
        capital: 'Maryland',
    },
    MA: {
        label: 'Massachusetts',
        capital: 'Massachusetts',
    },
    MI: {
        label: 'Michigan',
        capital: 'Michigan',
    },
    MN: {
        label: 'Minnesota',
        capital: 'Minnesota',
    },
    MS: {
        label: 'Mississippi',
        capital: 'Mississippi',
    },
    MO: {
        label: 'Missouri',
        capital: 'Missouri',
    },
    MT: {
        label: 'Montana',
        capital: 'Montana',
    },
    NE: {
        label: 'Nebraska',
        capital: 'Nebraska',
    },
    NV: {
        label: 'Nevada',
        capital: 'Nevada',
    },
    NH: {
        label: 'New Hampshire',
        capital: 'New Hampshire',
    },
    NJ: {
        label: 'New Jersey',
        capital: 'New Jersey',
    },
    NM: {
        label: 'New Mexico',
        capital: 'New Mexico',
    },
    NY: {
        label: 'New York',
        capital: 'New York',
    },
    NC: {
        label: 'North Carolina',
        capital: 'North Carolina',
    },
    ND: {
        label: 'North Dakota',
        capital: 'North Dakota',
    },
    OH: {
        label: 'Ohio',
        capital: 'Ohio',
    },
    OK: {
        label: 'Oklahoma',
        capital: 'Oklahoma',
    },
    OR: {
        label: 'Oregon',
        capital: 'Oregon',
    },
    PA: {
        label: 'Pennsylvania',
        capital: 'Pennsylvania',
    },
    RI: {
        label: 'Rhode Island',
        capital: 'Rhode Island',
    },
    SC: {
        label: 'South Carolina',
        capital: 'South Carolina',
    },
    SD: {
        label: 'South Dakota',
        capital: 'South Dakota',
    },
    TN: {
        label: 'Tennessee',
        capital: 'Tennessee',
    },
    TX: {
        label: 'Texas',
        capital: 'Texas',
    },
    UT: {
        label: 'Utah',
        capital: 'Utah',
    },
    VT: {
        label: 'Vermont',
        capital: 'Vermont',
    },
    VA: {
        label: 'Virginia',
        capital: 'Virginia',
    },
    WA: {
        label: 'Washington',
        capital: 'Washington',
    },
    WV: {
        label: 'West Virginia',
        capital: 'West Virginia',
    },
    WI: {
        label: 'Wisconsin',
        capital: 'Wisconsin',
    },
    WY: {
        label: 'Wyoming',
        capital: 'Wyoming',
    },
}

export const getCapitalCity = (state: string): string => {
    if (statesWithCities[state]) return statesWithCities[state]?.capital

    const stateByLabel = Object.values(statesWithCities).find((item) => item.label === state)
    if (stateByLabel) return stateByLabel?.capital
}
