const searchBPL = /* GraphQL */ `
query SearchBPL($input: inputSearchBPL!) {
    searchBPL(input: $input) {  
        nextToken
        returnedCount
        totalResults
        items {
            __typename
            id
            slug
            title
            ... on Brand {
                displayTitle
                address {
                    street
                    city
                    state
                    postalCode
                }
                assets {
                    __typename
                    alt
                    caption
                    ... on S3Asset {
                        path
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
                traits {
                    status
                }
            }
            ... on Location {
                address {
                    street
                    city
                    state
                }
                brand {
                    id
                    slug
                    title
                    displayTitle
                    assets {
                        __typename
                        alt
                        caption
                        ... on S3Asset {
                        path
                        }
                        ... on CloudinaryAsset {
                        cloudinaryId
                        }
                    }
                }
                traits {
                    status
                }
            }
            ... on Product {
                title
                color
                abv
                isHiddenExternally
                assets {
                    __typename
                    alt
                    caption
                    category
                    tags
                    ... on S3Asset {
                        path
                        filename
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
                brand {
                    id
                    slug
                    title
                    displayTitle
                    assets {
                        __typename
                        alt
                        type
                        caption
                        ... on S3Asset {
                        path
                        }
                        ... on CloudinaryAsset {
                        cloudinaryId
                        }
                    }
                }
                ... on Beer {
                    ibu
                    srm
                    styleAlias
                    style {
                        id
                        slug
                        title
                    }
                }
                ... on Kombucha {
                    kombuchaFamily
                }
                ... on Cider {
                    ciderFamily
                }
            }
        }            
    }
}
`

export default searchBPL
