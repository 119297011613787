import {
    InputSearchBpl,
    InputSearchBrandsLocationsProducts,
    LocationStatus,
    OpenSearchType,
    WildCardTitleSearchConnection,
} from 'src/types/schema/graphql'
import { useSearch } from '../../state/useSearch'
import { apiRequest, RequestInput } from 'src/api/apiRequest'
import searchBPL from '../graph/searchBPL'

const getSearchResults = async (query: string, args?: InputSearchBrandsLocationsProducts) => {
    if (!query) return []
    const input: RequestInput<InputSearchBpl> = {
        query: searchBPL,
        args: {
            input: {
                limit: args?.limit,
                typeFilters: args?.search?.typeFilters || [
                    OpenSearchType.Brand,
                    OpenSearchType.Location,
                    OpenSearchType.Product,
                ],
                search: {
                    wildcardText: query,
                    searchFieldsLocations: {
                        status: LocationStatus.OpenForBusiness,
                    },
                },
                nextToken: args?.nextToken,
            },
        },
        file: 'getSearchResults.ts',
        endpoint: 'searchBPL',
        publicQuery: true,
        noAlerts: false,
    }
    const { data } = await apiRequest<WildCardTitleSearchConnection, InputSearchBpl>(input)
    if (data) {
        const results = data?.items
        const nextToken =
            Number(data?.nextToken) > 0 ? data?.nextToken : useSearch.getState().nextToken

        useSearch.setState({
            nextToken: nextToken,
            totalResults: data?.totalResults || 0,
        })
        return results
    } else return []
}

export default getSearchResults
